import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Home({ data }) {
  return <Layout>
    <SEO title="Home" />
	  {data.allToml.edges.map(({ node }) => (
		  <div id={node.id}>
	  <Link to={node.fields.slug}>{node.title}</Link>
		  </div>
	  ))}
  </Layout>
}

export const query = graphql`
  query {
    allToml(sort: { fields: [fields___slug], order: ASC }) {
      totalCount
      edges {
        node {
          id
          title
          fields {
            slug
          }
        }
      }
    }
  }
`
